import { PrismicLink, ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { ReviewCard, ReviewCardNewHomePageUI } from './review-card';
import { useCallback, useEffect, useState } from 'react';
import { wordify } from '@ee-monorepo/shared/utilities/functions';
import useEmblaCarousel from 'embla-carousel-react';
import { Button } from '@ee-monorepo/shared/ui/button';
import { PrismicText } from '@prismicio/react';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';
import { Divider } from '@mui/material';
import { getStyle } from './reviews.styles';

export const Reviews = ({ index, slice: { variation, primary, items } }) => {
  const isMobile = useHandHeld();
  const styles = getStyle(variation, {
    background_color: primary?.background_color || '',
    heading_text_color: primary?.heading_text_color || '',
    heading_text_font: primary?.heading_text_font || '',
    style_reviews_box: primary?.style_reviews_box || false,
    isMobile,
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const reviewData = items;
  const [emblaRef, emblaMainApi] = useEmblaCarousel({
    dragFree: false,
    containScroll: 'trimSnaps',
    align: 'center',
  });

  const onSelect = useCallback(() => {
    if (!emblaMainApi) return;
    isMobile && setActiveIndex(emblaMainApi.selectedScrollSnap());
  }, [emblaMainApi, setActiveIndex]);

  const reviewSection = reviewData.map((item, i) => {
    return (
      <div key={i}>
        <ReviewCard
          item={item}
          index={i}
          active={activeIndex === i ? true : false}
          updateActiveIndex={() => setActiveIndex(i)}
        />
      </div>
    );
  });

  const reviewSectionNewHomePageUI = reviewData.map((item, i) => {
    return (
      <>
        <div key={i}>
          <ReviewCardNewHomePageUI
            item={item}
            index={i}
            active={activeIndex === i ? true : false}
            updateActiveIndex={() => setActiveIndex(i)}
            starColor={primary?.star_color}
            reviewsTextColor={primary?.reviews_text_color}
            reviewsBackgroundColor={primary?.reviews_background_color}
            styleReviewsBox={primary?.style_reviews_box}
            customerNamePosition={primary?.customer_name_position}
          />
        </div>
        {primary?.show_divider && i < reviewData.length - 1 && (
          <div data-testid={'divider-' + i}>
            {isMobile ? (
              <Divider className={'mx-6 w-auto bg-SnapGreen'} flexItem />
            ) : (
              <Divider
                className={'mt-6 w-[1px] h-52 bg-SnapGreen'}
                orientation="vertical"
                flexItem
              />
            )}
          </div>
        )}
      </>
    );
  });

  useEffect(() => {
    if (!emblaMainApi) return;
    onSelect();
    emblaMainApi.on('select', onSelect);
  }, [emblaMainApi, onSelect]);

  return (
    <section
      data-testid="reviewSection"
      data-slice-index={index}
      className={styles.reviewSection}
    >
      <div className="snap-container">
        <h2
          id={wordify(primary?.title[0]?.text || '')}
          data-testid="reviewsSectionTitle"
          className={styles.title}
        >
          <PrismicText field={primary?.title} />
        </h2>
        {primary?.subtitle[0]?.text && (
          <h3
            id={wordify(primary?.subtitle[0]?.text || '')}
            className="text-SnapWhite mt-2 mb-0 text-left bodyFont md:headingM md:text-center"
          >
            <PrismicText field={primary?.subtitle} />
          </h3>
        )}
      </div>
      <div className="snap-container-no-pad">
        <div
          className={styles.reviewSectionContainerParent}
          ref={
            variation === 'default-slice' ||
            (variation === 'newHomePageUi' &&
              isMobile &&
              primary?.style_reviews_box)
              ? emblaRef
              : null
          }
        >
          <div className={styles.reviewSectionContainer}>
            {variation === 'newHomePageUi'
              ? reviewSectionNewHomePageUI
              : reviewSection}
          </div>
        </div>
      </div>
      <div className="snap-container">
        <div className="flex justify-center flex-col md:flex-row">
          {primary.button_link?.url && (
            <PrismicLink className="no-underline" link={primary?.button_link}>
              <Button
                className={styles.button}
                inverseMode={variation === 'default-slice'}
                variant={
                  variation === 'homePagenewHomePageUi'
                    ? 'secondary'
                    : 'primary'
                }
                data-testid="moreReviewsCta"
              >
                {primary?.button_label}
              </Button>
            </PrismicLink>
          )}
        </div>
        <div className="flex justify-center mt-5">
          <p className={styles.footnote}>
            <PrismicText field={primary?.footnote} />
          </p>
          <ImageWrapper
            className="ml-2"
            shouldSetHeightToAuto={false}
            src={primary.footnote_image?.url}
            alt={primary?.footnote_image?.alt}
            width={primary?.footnote_image?.dimensions?.width}
            height={primary?.footnote_image?.dimensions?.height}
          />
        </div>
      </div>
    </section>
  );
};
