import { getColor } from '@ee-monorepo/shared/utilities/functions';

interface GetStyleProps {
  background_color: string;
  heading_text_color: string;
  heading_text_font: string;
  style_reviews_box: boolean;
  isMobile: boolean;
}

interface ReviewsStyle {
  reviewSection: string;
  title: string;
  reviewSectionContainerParent: string;
  reviewSectionContainer: string;
  button: string;
  footnote: string;
}

export const getStyle = (
  variation: 'default-slice' | 'newHomePageUi',
  props: GetStyleProps
) => {
  const styleObject = styles(props);
  return styleObject[variation] || styleObject['default-slice'];
};

const styles = ({
  background_color,
  heading_text_color,
  heading_text_font,
  style_reviews_box,
  isMobile,
}: GetStyleProps): { [key: string]: ReviewsStyle } => {
  const titleFont =
    heading_text_font === 'headingL_mktg'
      ? `headingMHeavy_mktg md:headingL_mktg`
      : `headingMHeavy_mktg md:headingXL_mktg`;

  return {
    'default-slice': {
      reviewSection: 'bg-SnapDarkBlue text-center py-14 md:py-16',
      title:
        'text-SnapLightBlue text-left headingL md:headingXL md:text-center',
      reviewSectionContainerParent: 'overflow-hidden',
      reviewSectionContainer: 'flex md:my-16 md:pt-2 sm:my-8 sm:pt-2',
      button: 'm-0 w-full',
      footnote: 'text-SnapWhite bodyHeavyFont mb-0 pt-[1px]',
    },

    newHomePageUi: {
      reviewSection:
        getColor(background_color).backgroundColorClass +
        ' text-center py-14 md:py-16',

      title:
        getColor(heading_text_color).textColorClass +
        ' text-left ml-2 md:text-center md:ml-none ' +
        titleFont,

      reviewSectionContainerParent:
        isMobile && style_reviews_box ? 'overflow-hidden ' : '',

      reviewSectionContainer: !isMobile
        ? style_reviews_box
          ? 'flex my-8 md:my-16 pt-2 justify-between'
          : 'flex mt-8 md:mt-16 pt-2 justify-between'
        : style_reviews_box
        ? 'flex'
        : 'flex flex-col',

      button: isMobile ? 'mx-4 w-[calc(100%-32px)]' : '',

      footnote:
        getColor(heading_text_color).textColorClass +
        ' bodyHeavyFont mb-0 pt-[1px]',
    },
  };
};
