import { IconPerson } from '@ee-monorepo/shared/ui/icons';
import { getInitialsFromName } from '@ee-monorepo/shared/utilities/functions';
import { colors } from '@snap/colors';
import cn from 'classnames';

type SizeType = 48 | 40 | 32 | 24 | 18;
type SizesMap = {
  [Property in SizeType]: string;
};

// font sizes based on avatar size variant prop
const fontSizes: SizesMap = {
  48: '14px',
  40: '14px',
  32: '14px',
  24: '10px',
  18: '8px',
};

// fallback icon sizes
const iconSizes: SizesMap = {
  48: '16px',
  40: '16px',
  32: '16px',
  24: '13px',
  18: '13px',
};

export interface SharedUiAvatarProps {
  displayText: string;
  url?: string;
  size?: SizeType;
  useIconFallback?: boolean;
}

export function Avatar(props: SharedUiAvatarProps) {
  const size = props?.size || 40;

  const iconStyle = { width: iconSizes[size] };

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
    fontSize: fontSizes[size],
  };

  return props?.url ? (
    <img
      className={cn('rounded-full')}
      style={sizeStyle}
      src={props.url}
      data-testid="avatar-image"
      alt={`${props.displayText} avatar`}
    />
  ) : (
    <span
      className="rounded-full flex justify-center bg-SnapGrey300 text-SnapDarkBlue items-center secondaryHeavyFont"
      style={sizeStyle}
    >
      {props.useIconFallback ? (
        <IconPerson iconColor={colors.SnapDarkBlue} style={iconStyle} />
      ) : (
        getInitialsFromName(props?.displayText || '')
      )}
    </span>
  );
}
