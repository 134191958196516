import * as prismicH from '@prismicio/helpers';
import { PrismicButton } from '@ee-monorepo/prismic/shared/ui';
import cn from 'classnames';
import { colors } from '@snap/colors';
import { getFormatDate } from './helper';
import s from './review-card.module.scss';
import dynamic from 'next/dynamic';
import { Avatar } from '@ee-monorepo/shared/ui/avatar';
import { PrismicText } from '@prismicio/react';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import { useHandHeld } from '@ee-monorepo/shared/utilities/hooks';

const StarRatings: any = dynamic(() => import('react-star-ratings'), {
  ssr: false,
});

export const ReviewCard = ({ item, index, updateActiveIndex, active }) => {
  const {
    customer_name,
    customer_profile,
    customer_rating,
    date_of_review,
    external_link,
    external_link_label,
    review_description,
    review_title,
  } = item;
  const numberOfDays = date_of_review ? getFormatDate(date_of_review) : '';

  return (
    <div
      data-testid={'review-card' + index}
      className={cn('p-6 md:p-6', s.reviewCardContainer, s.slide, {
        'bg-SnapWhite': active,
        [s.selectedReview]: active,
      })}
      onMouseOver={() => updateActiveIndex(index)}
      onClick={() => updateActiveIndex(index)}
    >
      <div className="flex mb-2">
        <div className="block">
          <Avatar
            url={customer_profile?.url}
            displayText={
              customer_profile?.alt
                ? customer_profile?.alt
                : prismicH.asText(customer_name)
            }
            size={customer_profile?.dimensions?.height || 48}
          />
        </div>
        <div className="flex flex-col justify-end ml-4">
          <h4
            className={cn(
              active ? 'text-SnapGrey500' : 'text-SnapWhite',
              'headingM',
              'text-start',
              s.reviewerName
            )}
          >
            <PrismicText field={customer_name} />
          </h4>
          <div className={cn('text-start', s.starContainer)}>
            <StarRatings
              rating={customer_rating === null ? 0 : customer_rating}
              starRatedColor={
                active ? colors.SnapMediumBlue : colors.SnapLightBlue
              }
              starEmptyColor={
                active ? 'rgba(54, 107, 221, 0.3)' : 'rgba(255, 255, 255, 0.3)'
              }
              starSpacing="0"
              starDimension="20px"
              numberOfStars={5}
            />
            <span
              className={cn(
                'text-SnapLightBlue',
                'inline-block',
                'ml-2',
                'secondaryFont',
                {
                  'text-SnapGrey100': active,
                }
              )}
            >
              {numberOfDays}
            </span>
          </div>
        </div>
      </div>
      <h5
        className={cn(
          active ? 'text-SnapGrey500' : 'text-SnapWhite',
          'text-start',
          'bodyHeavyFont',
          'mb-0'
        )}
      >
        {<PrismicText field={review_title} />}
      </h5>
      <p
        className={cn(
          active ? 'text-SnapGrey500' : 'text-SnapWhite',
          'text-start',
          'bodyFont',
          'mb-0',
          s.reviewDescription
        )}
      >
        <PrismicText field={review_description} />
      </p>
      <div className={cn('text-start', s.linkContainer)}>
        <PrismicButton
          link={external_link}
          variant="link"
          buttonClassName={cn(`${active ? '' : 'text-SnapLightBlue'}`)}
          label={external_link_label}
          showButton={true}
        />
      </div>
    </div>
  );
};

export const ReviewCardNewHomePageUI = ({
  item,
  index,
  updateActiveIndex,
  active,
  starColor,
  reviewsTextColor,
  reviewsBackgroundColor,
  styleReviewsBox,
  customerNamePosition,
}) => {
  const {
    customer_name,
    customer_profile,
    customer_rating,
    date_of_review,
    external_link,
    external_link_label,
    review_description,
    review_title,
  } = item;

  const isMobile = useHandHeld();

  return (
    <div
      data-testid={'review-card' + index}
      className={cn(
        getColor(reviewsBackgroundColor).backgroundColorClass,
        'p-6 md:p-6',
        styleReviewsBox &&
          'rounded-lg shadow-[-3px_2px_20px_0_rgba(0,0,0,0.06),2px_2px_6px_0_rgba(0,0,0,0.10)]',
        {
          'w-[392px] h-[320px]': !isMobile,
          'w-[286px] h-[316px] my-8 mx-4': isMobile && styleReviewsBox,
          'w-auto h-auto': isMobile && !styleReviewsBox,
        }
      )}
      onMouseOver={() => updateActiveIndex(index)}
      onClick={() => updateActiveIndex(index)}
    >
      <div className={cn('flex justify-between')}>
        <StarRatings
          rating={customer_rating === null ? 0 : customer_rating}
          starRatedColor={
            colors[`Snap${starColor[0].toUpperCase() + starColor.slice(1)}`]
          }
          starEmptyColor={'rgba(255, 255, 255, 0.3)'}
          starSpacing="0"
          starDimension="20px"
          numberOfStars={5}
        />
        <div data-testid={'dateOfReview' + index}>
          {date_of_review &&
            prismicH.asDate(date_of_review).toLocaleDateString('en-US', {
              timeZone: 'UTC',
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            })}
        </div>
      </div>
      {customerNamePosition === 'top' && (
        <h4
          className={cn(
            getColor(reviewsTextColor).textColorClass,
            'bodyHeavyFont',
            'text-start',
            'mt-5'
          )}
        >
          <PrismicText field={customer_name} />
        </h4>
      )}
      <p
        className={cn(
          getColor(reviewsTextColor).textColorClass,
          'text-start',
          'bodyFont',
          'mb-0',
          'mt-5'
        )}
      >
        <PrismicText field={review_description} />
      </p>
      {customerNamePosition !== 'top' && (
        <h4
          className={cn(
            getColor(reviewsTextColor).textColorClass,
            'bodyHeavyFont',
            'text-start',
            'mt-5'
          )}
        >
          <PrismicText field={customer_name} />
        </h4>
      )}
    </div>
  );
};
